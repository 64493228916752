
.paper-head {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    .topic-category {
        display: flex;
        align-items: center;
        color: #13131b;
        .select-box {
            width: 170px;
            margin-right: 9px;
        }
        .search-box {
            width: 250px;
            .el-icon-search {
                cursor: pointer;
            }
        }
    }
}
